.Container {
    position: relative;
    min-height: 300px;
    max-height: 100vh;
    overflow: hidden;
    background-color: black;
    margin:-8px;
}

/* Style the video: 100% width and height to cover the entire window */
.Video {
    width: 100%;
    height: 100%;
  }
  
  /* Add some content at the bottom of the video/page */
  .Content {
    z-index:1;
    position: fixed;
    left:10px;
    bottom: 20px;
    width: 100%;
    padding-top: 9px;
  }
  .Content a{color: hotpink;
  }
  .Content a:hover{color: blueviolet;
  }

  .Mutebutton {
    background-color:rgba(255, 255, 255,0.1);
    z-index:1;
    position: fixed;
    bottom:2vw;
    right: 2vw;
    width: 4vw;
    height: 4vw;
    border: none;
    cursor: pointer;
    border-radius:50%;
  }

  .Mutebutton :hover {
    background-color:rgba(255, 255, 255, 0.05);
    border-radius:50%;
  }

  .Logo {
    background-color:rgba(255, 255, 255,0);
    z-index:1;
    position: fixed;
    top:2vw;
    right: 2vw;
    width: 5vw;
    height: 5vw;
    border: none;
    border-radius:50%;
  }

  .Skipbutton {
    background-color:rgba(255, 255, 255,0);
    z-index:1;
    position: fixed;
    bottom:7vw;
    right: 2vw;
    width: 4vw;
    height: 4vw;
    border: none;
    border-radius:50%;
    cursor: pointer;
  }

  .Skipbutton :hover {
    background-color:rgba(255, 255, 255, 0.05);
    border-radius:75%;
  }